body {
  background-color: #101a24;
}

line {
  stroke: dodgerblue;
  stroke-width: 1.8px;
}

select {
  font-family: "Black Han Sans", sans-serif;
  font-weight: lighter;
  font-size: 1.8rem;
  text-align: center;
  padding: 0.1em;
}

div {
  color: wheat;
  font-family: "Black Han Sans", sans-serif;
  font-weight: 100;
}

#title {
  font-size: 2em;
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5em;
}

footer {
  color: #b2b2b2;
  text-align: center;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 0.5em;
  opacity: 0.7;
}

hr {
  border: solid 1px #4c4c4c;
  width: 70%;
  margin-top: 3%;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0.3em;
  flex-wrap: wrap;
}

#full_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#map {
  transition: 0.5s;
  width: 768px;
  height: 768px;
}

.mapimg {
  width: 768px;
  height: 768px;
  max-width: 100%;
}

#rotateBtn {
  font-size: 1.3em;
  padding: 0.1em;
  margin-right: 0.5em;
  margin-left: 0.2em;
  border: 0;
}

#selectGroup {
  display: flex;
  align-items: center;
}

svg * {
  transform-box: fill-box;
  transform-origin: center;
}

@media screen and (max-width: 800px) {
  #map {
    transition: 0.5s;
    width: 307px;
    height: 307px;
  }
  .mapimg {
    width: 307px;
    height: 307px;
  }
  #title {
    font-size: 1.5em;
  }
  select {
    font-family: "Black Han Sans", sans-serif;
    font-weight: lighter;
    font-size: 1.2rem;
    text-align: center;
    padding: 0.1em;
  }
  footer {
    text-align: center;
    font-family: "Noto Sans KR", sans-serif;
    font-size: 0.1em;
    opacity: 0.7;
  }

  hr {
    width: 90%;
  }
}
