.VIPER {
  fill-opacity: 0.1;
  stroke-opacity: 0.8;
  stroke-width: 2px;
  stroke: #93f55e;
  fill: #24a73d;
  r: 21.75;
}

.charText {
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 700;
  text-orientation: sideways-right;
  text-shadow: -1.5px 0px #ece8e1, 0px 1.5px #ece8e1, 1.5px 0px #ece8e1,
    0px -1.5px #ece8e1;
  opacity: 0.7;

  font-size: 0.85em;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.lineupDot {
  r: 2.5;
  stroke: black;
  stroke-width: 1.5px;
}

@media screen and (max-width: 800px) {
  .VIPER {
    fill-opacity: 0.1;
    stroke-opacity: 0.8;
    stroke-width: 1px;
    stroke: #93f55e;
    fill: #24a73d;
    r: 8.7;
  }
}
