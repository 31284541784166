.ytbBtn {
  font-family: "Noto Sans KR", sans-serif;
  text-align: center;
  display: inline;
  font-size: 1em;
  background-color: #ece8e1;
  border: none;
  padding: 0.2em;
  width: 400px;
  margin: 0.1em;
  cursor: pointer;
}

#btnGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#ytbVideo {
  width: 560px;
  height: 315px;
}

@media screen and (max-width: 800px) {
  #ytbVideo {
    width: 280px;
    height: 160px;
  }
  .ytbBtn {
    font-family: "Noto Sans KR", sans-serif;
    text-align: center;
    display: inline;
    font-size: 0.5em;
    background-color: #ece8e1;
    border: none;
    padding: 0.2em;
    width: 180px;
    margin: 0.1em;
    cursor: pointer;
  }
  #btnGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
